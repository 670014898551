/** @format */

import styled from "styled-components";
import { GatsbyImage } from "gatsby-plugin-image";

export const StyledOnasSection = styled.section`
    width: 100%;
    padding-top: 40px;
    display: flex;
    flex-direction: column;
    align-items: center;
`;
export const StyledHeaderText = styled.h2`
    font-family: "Roboto", sans-serif;
    font-size: 54px;
    font-weight: 500;
    padding: 0 40px;
    @media only screen and (max-width: 767px){
        font-size: 44px;
        padding: 0 10px;
    }
`;
export const StyledHeaderWrapper = styled.div`
    max-width: 1400px;
    margin: 40px auto 0 auto;
    width: 100%;
    align-self: flex-start;
    @media only screen and (max-width: 440px){
        margin-top: 0;
    }
`;

export const StyledOnasCard = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 40px;
    max-width: 1400px;
    margin: 40px auto 0 auto;
    @media only screen and (max-width: 767px){
        padding: 0 10px;
    }
`;
export const StyledLeftWrapper = styled.div`
    width: ${({ haswidth }) => haswidth};
    padding: ${({ haspaddingleft }) =>
        haspaddingleft ? "0 0 0 40px" : "0 40px 0 0"};
    > h3 {
        font-family: "Roboto", sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: 32px;
        line-height: 1.6em;
    }
    > p {
        margin-top: 40px;
        font-family: "Roboto", sans-serif;
        font-style: normal;
        font-weight: 300;
        font-size: 24px;
        line-height: 1.6em;
        text-align: center;
        a {
            text-decoration: none;
            color: #a0ffc5;
            transition: color 250ms;
            &:hover{
                color: #11ca59;
            }
        }
    }

    @media only screen and (max-width: 1014px){
        > h3 {
            font-size: 28px;
        }
        > p {
            font-size: 20px;
        }
    }
    @media only screen and (max-width: 767px){
        > h3 {
            font-size: 24px;
        }
        > p {
            margin-top: 10px;
            font-size: 16px;
        }
    }
    @media only screen and (max-width: 658px){
        width: 100%;
        padding: 0 10px;
    }
`;
export const StyledRightWrapper = styled.div`
    width: ${({ haswidth }) => haswidth};
    @media only screen and (max-width: 658px){
        display: none;
    }
`;
export const StyledCardImage = styled(GatsbyImage)`
    width: 100%;
    max-height: 626px;
    box-shadow: 13px 8px 17px 6px rgba(0, 0, 0, 0.42);
    border-radius: 22px;
    @media only screen and (max-width: 1014px){
        max-height: 470px;
    }
`;
export const StyledAlongTime = styled.div`
    width: 100%;
    padding: 0 40px;
    max-width: 1400px;
    margin: 0 auto;
    > p {
        font-family: "Roboto", sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: 32px;
        line-height: 160.7%;
        text-align: center;
        max-width: 800px;
        margin: 80px auto 0 auto;
    }
    @media only screen and (max-width: 918px){
        > p {
            font-size: 26px;
        }
    }
    @media only screen and (max-width: 767px){
        padding: 0 10px;
        > p {
            font-size: 20px;
        }
    }
    @media only screen and (max-width: 658px){
        > p{
            margin-top: 40px;
        }
    }
`;
