/** @format */

import React, { useState, useEffect } from "react";

import { graphql, useStaticQuery } from "gatsby";
import { AnimatePresence, AnimateSharedLayout } from "framer-motion";

import {
    StyledCoONasMowia,
    StyledWrapperWithoutPagination,
    StyledLeftArrow,
    StyledRightArrow,
    StyledSlide,
    StyledPagination,
    StyledSpanPagination,
    StyledButtonPagination,
    StyledSlideWrapper,
} from "./StyledCoONasMowia";

import Left from "../../images/left.svg";
import Right from "../../images/right.svg";

const CoONasMowia = () => {
    const whatSayAboutMeContent = useStaticQuery(graphql`
        query Opnie {
            allDatoCmsOpinieOAgroturystyce {
                edges {
                    node {
                        opinia
                    }
                }
            }
        }
    `);

    const [slide, setSlide] = useState(0);
    const data = whatSayAboutMeContent.allDatoCmsOpinieOAgroturystyce.edges;
    const paginate = (newDirection) => {
        if (newDirection === 1) {
            if (slide === data.length - 1) {
                setSlide(0);
            } else {
                setSlide(slide + newDirection);
            }
        }
        if (newDirection === -1) {
            if (slide === 0) {
                setSlide(data.length - 1);
            } else {
                setSlide(slide + newDirection);
            }
        }
    };
    const handleSetSlide = (iterator) => {
        setSlide(iterator);
    };

    return (
        <StyledCoONasMowia>
            <div>
                <AnimateSharedLayout type="crossfade">
                    <StyledSlideWrapper>
                        <p>Co o nas mówią:</p>
                        <AnimatePresence
                            initial={false}
                            exitBeforeEnter
                            custom={slide}
                        >
                            {data
                                .filter((_, iterator) => iterator === slide)
                                .map((slide) => (
                                    <StyledSlide
                                        key={slide.node.opinia}
                                        layout
                                        initial={{ opacity: 0 }}
                                        animate={{ opacity: 1 }}
                                        exit={{ opacity: 0 }}
                                        transition={{
                                            duration: 0.3,
                                        }}
                                    >
                                        <StyledWrapperWithoutPagination
                                            key={`${slide.node.opinia}-wrapper`}
                                            layout
                                            initial={{ opacity: 0 }}
                                            animate={{ opacity: 1 }}
                                            exit={{ opacity: 0 }}
                                            transition={{
                                                type: "spring",
                                                duration: 0.3,
                                            }}
                                        >
                                            <StyledLeftArrow>
                                                <StyledButtonPagination
                                                    whileHover={{
                                                        scale: 1.2,
                                                        transition: {
                                                            duration: 0.5,
                                                        },
                                                    }}
                                                    whileTap={{ scale: 0.9 }}
                                                    onClick={() => paginate(-1)}
                                                    layout
                                                >
                                                    <Left />
                                                </StyledButtonPagination>
                                            </StyledLeftArrow>
                                            <p>{slide.node.opinia}</p>
                                            <StyledRightArrow>
                                                <StyledButtonPagination
                                                    whileHover={{
                                                        scale: 1.2,
                                                        transition: {
                                                            duration: 0.5,
                                                        },
                                                    }}
                                                    whileTap={{ scale: 0.9 }}
                                                    onClick={() => paginate(1)}
                                                >
                                                    <Right />
                                                </StyledButtonPagination>
                                            </StyledRightArrow>
                                        </StyledWrapperWithoutPagination>
                                    </StyledSlide>
                                ))}
                        </AnimatePresence>
                    </StyledSlideWrapper>
                    <StyledPagination layout>
                        {data.map((slideItem, iterator) => (
                            <StyledSpanPagination
                                key={`pagination-${iterator}`}
                                active={iterator === slide}
                                onClick={() => handleSetSlide(iterator)}
                                whileHover={{
                                    scale: 1.1,
                                    transition: {
                                        duration: 0.2,
                                    },
                                }}
                                whileTap={{ scale: 0.9 }}
                            ></StyledSpanPagination>
                        ))}
                    </StyledPagination>
                </AnimateSharedLayout>
            </div>
        </StyledCoONasMowia>
    );
};

export default CoONasMowia;
