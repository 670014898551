/** @format */

import React from "react";

import GlobalLayout from "../components/layouts/GlobalLayout";
import SiteHeader from "../components/SiteHeader/SiteHeader";
import OnasSection from "../components/OnasSection/OnasSection";
import Footer from "../components/Footer/Footer";
import HelmetTemplate from "../components/HelmetTemplate/HelmetTemplate";

const Onas = () => {
    return (
        <GlobalLayout>
            <HelmetTemplate
                title="O nas"
                desc="Jak zaczeła się nasza historia"
            />
            <SiteHeader />
            <OnasSection />
            <Footer />
        </GlobalLayout>
    );
};

export default Onas;
