/** @format */

import styled from "styled-components";
import { motion } from "framer-motion";

export const StyledCoONasMowia = styled.div`
    width: 100%;
    background-color: #11ca59;
    margin-top: 111px;
    padding-bottom: 50px;
    @media only screen and (max-width: 658px){
        margin-top: 40px;
    }
`;
export const StyledWrapperWithoutPagination = styled(motion.div)`
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 20px;
    > p {
        font-family: "Roboto";
        font-style: normal;
        font-weight: 300;
        font-size: 20px;
        line-height: 160.7%;
        text-align: center;
        color: #fff;
        padding: 0 30px;
    }
    @media only screen and (max-width: 767px){
        > p{
            font-size: 16px;
        }
    }
    @media only screen and (max-width: 454px){
        > p {
            padding: 0 10px;
        }
    }
`;
export const StyledLeftArrow = styled(motion.div)``;
export const StyledButtonPagination = styled(motion.button)`
    background: transparent;
    border: none;
    cursor: pointer;
`;
export const StyledRightArrow = styled(motion.button)`
    background: transparent;
    border: none;
    cursor: pointer;
`;
export const StyledSlide = styled.div``;
export const StyledPagination = styled.div`
    display: flex;
    justify-content: space-between;
    max-width: 500px;
    margin: 40px auto 0 auto;
    padding: 0 40px;
    @media only screen and (max-width: 767px){
        max-width: 400px;
    }
    @media only screen and (max-width: 454px){
        max-width: 320px;
    }
`;
export const StyledSpanPagination = styled(motion.div)`
    display: block;
    width: 16px;
    height: 16px;
    background-color: ${({ active }) => (active ? "#000" : "#fff")};
    border-radius: 50%;
    cursor: pointer;
    transition: background-color 250ms;
    @media only screen and (max-width: 454px){
        width: 12px;
        height: 12px;
    }
`;

export const StyledSlideWrapper = styled.div`
    display: flex;
    align-items: center;
    flex-direction: column;
    max-width: 1200px;
    margin: 0 auto;
    > p {
        padding-top: 50px;
        font-style: normal;
        font-weight: 300;
        font-size: 40px;
        line-height: 160.7%;
        font-family: "Roboto", sans-serif;
        color: #fff;
    }
    @media only screen and (max-width: 767px){
        > p{
            font-size: 32px;
        }
    }
`;
