/** @format */

import React from "react";
import CoONasMowia from "../CoONasMowia/CoONasMowia";
import {
    StyledOnasSection,
    StyledHeaderText,
    StyledOnasCard,
    StyledLeftWrapper,
    StyledRightWrapper,
    StyledCardImage,
    StyledAlongTime,
    StyledHeaderWrapper
} from "./StyledOnasSection";
import { useStaticQuery, graphql, Link } from "gatsby";
import { getImage } from "gatsby-plugin-image";

const OnasSection = () => {
    const data = useStaticQuery(graphql`
        query Onas {
            datoCmsONasStrona {
                coNasWyrozniaOpis
                coNasWyrozniaTytul
                gdzieSieZnajdujemy
                gdzieSieZnajdujemyOpis
                naszaAgroturystykaDziala
                naszaHistoriaLinkTekst
                naszaHistoriaOpis
                naszaHistoriaTytul
                gdzieSieZnajdujemyZdjecie {
                    gatsbyImageData
                }
                naszaHistoriaZdjecie {
                    gatsbyImageData
                }
                coNasWyrozniaZdjecie {
                    gatsbyImageData
                }
            }
        }
    `);
    const image1 = getImage(data.datoCmsONasStrona.gdzieSieZnajdujemyZdjecie);
    const image2 = getImage(data.datoCmsONasStrona.naszaHistoriaZdjecie);
    const image3 = getImage(data.datoCmsONasStrona.coNasWyrozniaZdjecie);

    return (
        <StyledOnasSection>
            <StyledHeaderWrapper>
                <StyledHeaderText>O nas:</StyledHeaderText>
            </StyledHeaderWrapper>
            <StyledOnasCard>
                <StyledLeftWrapper haswidth="60%">
                    <h3>{data.datoCmsONasStrona.gdzieSieZnajdujemy}</h3>
                    <p>{data.datoCmsONasStrona.gdzieSieZnajdujemyOpis}</p>
                </StyledLeftWrapper>
                <StyledRightWrapper haswidth="40%">
                    <StyledCardImage
                        image={image1}
                        alt="Przystanek na Leśnej"
                    />
                </StyledRightWrapper>
            </StyledOnasCard>
            <StyledOnasCard>
                <StyledRightWrapper haswidth="40%">
                    <StyledCardImage
                        image={image3}
                        alt="Przystanek na Leśnej"
                    />
                </StyledRightWrapper>
                <StyledLeftWrapper haswidth="60%" haspaddingleft={true}>
                    <h3>{data.datoCmsONasStrona.coNasWyrozniaTytul}</h3>
                    <p>{data.datoCmsONasStrona.coNasWyrozniaOpis}</p>
                </StyledLeftWrapper>
            </StyledOnasCard>
            <StyledOnasCard>
                <StyledLeftWrapper haswidth="60%">
                    <h3>{data.datoCmsONasStrona.naszaHistoriaTytul}</h3>
                    <p>
                        {data.datoCmsONasStrona.naszaHistoriaOpis}
                        {"\u00A0"}
                        <Link to="/pokoje">
                            {data.datoCmsONasStrona.naszaHistoriaLinkTekst}
                        </Link>
                    </p>
                </StyledLeftWrapper>
                <StyledRightWrapper haswidth="40%">
                    <StyledCardImage
                        image={image2}
                        alt="Przystanek na Leśnej"
                    />
                </StyledRightWrapper>
            </StyledOnasCard>
            <StyledAlongTime>
                <p>{data.datoCmsONasStrona.naszaAgroturystykaDziala}</p>
            </StyledAlongTime>
            <CoONasMowia />
        </StyledOnasSection>
    );
};

export default OnasSection;
